import { KeyName } from '@/models/KeyName.model';

/**
 *  Model that is FE using
 */
export class FilterVariable {
	public type: string;
	public list: KeyName[];

	constructor(obj: FilterVariable) {
		this.type = obj.type;
		this.list = obj.list;
	}
}
