
import { defineComponent } from 'vue';
import { capitalizeWord, generateId } from '@/utils/utils';
import { NULL_PLACEHOLDER } from '@/utils/constants.utils';

enum ComponentEvent {
	CHECK = 'check',
}

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'CButtonRadio',
	props: {
		name: {
			required: true,
			type: String,
		},
		value: {
			required: true,
			type: String,
		},
		checked: {
			required: false,
			type: Boolean,
			default: false,
		},
		label: {
			required: false,
			type: String,
			default: '',
		},
	},
	setup() {
		return {
			id: generateId(),
			capitalizeWord,
			NULL_PLACEHOLDER,
		};
	},
	methods: {
		onChange(event: Event) {
			this.$emit(ComponentEvent.CHECK, event);
		},
	},
});
