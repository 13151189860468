
import { CSSProperties, defineComponent, PropType } from 'vue';
import { generateId } from '@/utils/utils';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'CGrid',
	props: {
		list: {
			required: true,
      type: null as unknown as PropType<Array<Record<string, string>> | null>,
      validator: (v: never) => Array.isArray(v) || v === null,
		},
		label: {
			required: false,
			type: String,
			default: () => '',
		},
		itemWidth: {
			required: false,
			type: String,
			default: '',
		},
		itemHeight: {
			required: false,
			type: String,
			default: '',
		},
		itemMarginRight: {
			required: false,
			type: String,
			default: '',
		},
		itemMarginBottom: {
			required: false,
			type: String,
			default: '',
		},
		hasDividerStart: {
			required: false,
			type: Boolean,
			default: false,
		},
		hasDividerEnd: {
			required: false,
			type: Boolean,
			default: false,
		},
		gridStyle: {
			required: false,
			type: Object as PropType<CSSProperties>,
			default: () => ({}),
		},
	},
	setup() {
		return {
			id: generateId(),
		};
	},
});
